import React from "react";

const Navbar = () => {
    return (
        <nav className="navbar bg-body-tertiary">
            <div className="container-fluid">
                <span className="navbar-brand">
                    <img src="./ddb-logo.svg" alt="DDB" width="auto" height="60" />
                </span>
                {/* <form className="d-flex" role="search">
                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-success" type="submit">Search</button>

                <input className="form-control me-2" onChange={(e) => { onSearch(e) }} type="search" placeholder="Search" aria-label="Search"></input>
                </form> */}
            </div>
        </nav>
    );
}

export default Navbar