import React from "react";

const Contact = ({ contact }) => {
    return (
        <>
            <div className="card m-1 py-3 col-sm-6 col-md-4 col-lg-3">
                <img src="./avatar.jpg" alt={contact.name} width="120" height="120" className="mx-auto mb-3 rounded-circle shadow" />
                {contact.name && <h4 className="">{contact.name}</h4>}
                {contact.title && <div className="text-muted small"><span className="bg-title text-light p-1 m-1 rounded px-2">{contact.title}</span></div>}
                <div className="mb-2"></div>
                {contact.email && <div className=""><a href={`mailto:${contact.email}`}>{contact.email}</a></div>}
                {contact.mobile && <div className=""><a href={`tel:${contact.mobile}`}>{contact.mobile}</a></div>}
                {contact.office && <div className=""><a href={`tel:${contact.office}`}>{contact.office}</a> {contact.extension && <span>x {contact.extension}</span>} </div>}
            </div>
        </>
    );
}

export default Contact