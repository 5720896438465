import { useState, useEffect } from "react";
import './App.css';

import Contact from './Contact';
/* import Navbar from "./Navbar"; */

// const API_URL = 'http://localhost/projects/contacts/';
const API_URL = 'http://contacts.ddbdubai.com/data/';

const App = () => {
    const [contacts, setContacts] = useState([]);
    const [searchTerm, setSearchTerm] = useState();
    const [filtered, setFiltered] = useState([]);

    const fetchContacts = async () => {

        try {
            const response = await fetch(`${API_URL}`, { mode: 'cors' });
            // const response = await fetch('http://localhost:8080/cors', { mode: 'cors' });
            const data = await response.json();
            setContacts(data.contacts);

        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchContacts();
    }, [])

    function onSearch(e) {

        const searchText = e.target.value.toLocaleLowerCase();
        // const filteredItems = contacts.filter((item) => item.name.toLocaleLowerCase().includes(searchText));
        const filteredItems = contacts.filter((item) => item.name.toLocaleLowerCase().includes(searchText) || item.title.toLocaleLowerCase().includes(searchText));

        setSearchTerm(searchText);
        setFiltered(filteredItems);
    }

    return (
        <div className="container text-center">

            <div className="col col-md-8 col-lg-6 mx-auto mt-3">
                <input className="search" onChange={(e) => { onSearch(e) }} type="search"></input>
            </div>

            <div className="row justify-content-center p-3">
                {
                    searchTerm?.length > 0
                        ?
                        (
                            filtered.map((contact, index) => (

                                <Contact contact={contact} key={index} />
                            ))
                        )
                        :
                        (
                            contacts.map((contact, index) => (
                                <Contact contact={contact} key={index} />
                            ))
                        )

                }
            </div>


        </div>
    )
}

export default App
